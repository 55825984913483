import { Box, Button, Checkbox, useTheme } from '@mui/material';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import CommonModal from 'components/Common/CommonModal';
import { IPhotoIntelligenceData } from 'constants/photoIntelligence';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useAppDispatch } from 'store/hooks';
import { changeIsShowImageViewModal } from 'store/slices/map-view/photoIntelligenceAnalytics';

interface ImageViewModalProps {
  data: IPhotoIntelligenceData | undefined;
}

const ImageViewModal: FC<ImageViewModalProps> = ({ data }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { surroundingImages, kpName, names } = data || {};
  const images = surroundingImages || [];

  const dispatch = useAppDispatch();
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [selectedImage, setSelectedImage] = useState<string>(images[2]?.name || '');

  const handleImageClick = (imageName: string) => setSelectedImage(imageName);

  const toggleSelectImage = (imageName: string) => {
    setSelectedImages((prev) =>
      prev.includes(imageName) ? prev.filter((img) => img !== imageName) : [...prev, imageName]
    );
  };

  const downloadImagesAsZip = async (imageNames: string[], zipName: string) => {
    const zip = new JSZip();
    const folder = zip.folder('image-folder');

    const blobs = await Promise.all(
      imageNames.map(async (photo) => {
        const response = await fetch(`${photo}?cache-buster=${Date.now()}`);
        const blob = await response.blob();
        const photoName = photo.split('/').pop() || 'unknown';
        return { photoName, blob };
      })
    );

    blobs.forEach(({ photoName, blob }) => folder?.file(photoName, blob));
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    saveAs(zipBlob, zipName);
  };

  const handleDownload = (imageNames: string[], zipName: string) => downloadImagesAsZip(imageNames, zipName);

  console.log('kp', names);
  return (
    <CommonModal
      style={{ height: '900px', width: '935px', padding: '20px' }}
      isShow={true}
      handleClose={() => dispatch(changeIsShowImageViewModal({ id: '', isShow: false }))}>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <Box>{kpName}</Box>
        <Box sx={{ opacity: '0.5' }}>
          {names?.map((item) => t(convertTextToMultiLanguage(item, 'photo_intelligence'))).join(', ')}
        </Box>
      </Box>
      <Box sx={{ margin: '45px 23px 20px 23px', backgroundColor: theme.palette.color.grey2 }}>
        <TransformWrapper initialScale={1} limitToBounds={true}>
          <TransformComponent>
            <Box sx={{ textAlign: 'center', height: '635px', width: '848px' }}>
              {selectedImage && <img src={selectedImage} alt="Selected" height="100%" style={{}} />}
            </Box>
          </TransformComponent>
        </TransformWrapper>
      </Box>

      <Box sx={{ position: 'absolute', top: '30px', right: '10px' }}>
        {images.length > 0 && (
          <Button
            variant="text"
            sx={{ textDecoration: 'underline' }}
            onClick={() =>
              handleDownload(
                images.map((img) => img.name),
                'all_images.zip'
              )
            }>
            {t('trans.download_all')}
          </Button>
        )}
      </Box>

      {selectedImages.length > 0 && (
        <Box sx={{ position: 'absolute', top: '50px', right: '10px' }}>
          <Button
            variant="text"
            sx={{ textDecoration: 'underline', my: '6px' }}
            onClick={() => handleDownload(selectedImages, 'selected_images.zip')}>
            {t('trans.download_selected')}
          </Button>
        </Box>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {images.map((image) => (
          <Box
            key={image.position}
            sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', margin: '0 30px' }}>
            <img
              src={image.name}
              alt={image.position.toString()}
              height="100px"
              width="100px"
              style={{
                cursor: 'pointer',
                border: selectedImage === image.name ? '2px solid #4FE3AE' : 'none',
                borderRadius: '10px',
              }}
              onClick={() => handleImageClick(image.name)}
            />
            <Checkbox
              checked={selectedImages.includes(image.name)}
              onChange={() => toggleSelectImage(image.name)}
              sx={{ marginTop: '5px' }}
            />
          </Box>
        ))}
      </Box>
    </CommonModal>
  );
};

export default ImageViewModal;
