import { Paper, styled, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import { IPhotoIntelligenceData } from 'constants/photoIntelligence';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#fff !important' : '#6E6B7B !important',
  padding: '8px',
}));

interface TableInfoProps {
  data: IPhotoIntelligenceData;
}
const TableInfo: FC<TableInfoProps> = ({ data }) => {
  const { t } = useTranslation();
  const { names, severityLevels } = data || {};
  const tableData = names.map((name, index) => ({
    key: index,
    label: name,
    value: severityLevels[index],
  }));

  return (
    <TableContainer component={Paper} sx={{ mt: '12px' }}>
      <Table>
        <TableBody>
          {tableData.map((row) => (
            <TableRow key={row.key}>
              <CustomTableCell component="th" scope="row">
                {t(convertTextToMultiLanguage(row.label, 'photo_intelligence'))}
              </CustomTableCell>
              <CustomTableCell align="center">{t(convertTextToMultiLanguage(row.value, 'trans'))}</CustomTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableInfo;
