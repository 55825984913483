import ClearIcon from '@mui/icons-material/Clear';
import { DatePicker } from '@mui/lab';
import { IconButton, TextField, TextFieldProps } from '@mui/material';
import React from 'react';

interface DatePickerFilterProps {
  selectedDate: Date | null;
  onDateChange: (date: Date | null) => void;
  onClear: () => void;
  disabled: boolean;
}

const DatePickerFilter: React.FC<DatePickerFilterProps> = ({ selectedDate, onDateChange, onClear, disabled }) => {
  return (
    <DatePicker
      views={['year', 'month']}
      value={selectedDate}
      onChange={onDateChange}
      disabled={disabled}
      renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
        <TextField
          {...params}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            readOnly: true,
          }}
          sx={{
            width: '250px',
            '& .MuiOutlinedInput-root': {
              height: '35px',
            },
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {selectedDate && (
                  <IconButton onClick={onClear} size="small">
                    <ClearIcon />
                  </IconButton>
                )}
                {params.InputProps?.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default DatePickerFilter;
