import {
  Box,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { AscIcon, DescIcon } from 'assets/icons';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import CircleBox from 'components/KpDetail/CircleBox';
import { QUERY_KEY } from 'constants/constants';
import { IParent, IPhotoIntelligenceMLevel, SeverityColor, SeverityLevelEnum } from 'constants/photoIntelligence';
import useCheckingEnableAllLevels from 'hooks/common/useCheckingEnableAllLevels';
import { createRef, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getPhotoIntelligenceAllLocation, sendGetAllLocation } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { photoIntelligenceSelector } from 'store/slices/map-view/photoIntelligenceAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import TableCellHeaderSummary from '../components/TableCellHeaderSummary';
import TableCellSummary from '../components/TableCellSummary';

enum TableKeyEnum {
  LEVEL = 'Level',
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
  GROUND_FEATURES = 'Ground Features',
}

interface IKpData {
  key: SeverityLevelEnum;
  info: {
    value: number;
    names: string[];
  };
  color: string;
}
interface ITableData {
  _id: string;
  name: string;
  kpData: IKpData[];
}

interface IMapValue {
  value: number;
  names: string[];
}

interface PhotoIntelligenceSummaryTableProps {}

const PhotoIntelligenceSummaryTable: FC<PhotoIntelligenceSummaryTableProps> = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { clientId } = useParams();
  const { levelId, cropType } = useAppSelector(mapViewSelector);
  const { status, severityLevel, frequency } = useAppSelector(photoIntelligenceSelector);
  const [isSortAsc, setIsSortAsc] = useState<boolean>(true);
  const ref = createRef<HTMLDivElement>();
  const { isEnableAnalyticAllLevels } = useCheckingEnableAllLevels();

  const tableHeaders = [
    { key: TableKeyEnum.LEVEL, label: 'trans.level', sortOrder: null },
    { key: TableKeyEnum.HIGH, label: 'trans.high', sortOrder: null },
    { key: TableKeyEnum.MEDIUM, label: 'trans.medium', sortOrder: null },
    { key: TableKeyEnum.LOW, label: 'trans.low', sortOrder: null },
    { key: TableKeyEnum.GROUND_FEATURES, label: 'trans.ground_features', sortOrder: null },
  ];

  const { data: dataPhotoIntelligence } = useQuery<IPhotoIntelligenceMLevel[]>(
    [QUERY_KEY.ALL_LOCATION_PHOTO_INTELLIGENCE, levelId],
    () => getPhotoIntelligenceAllLocation(levelId!),
    {
      enabled: !!levelId && !isEnableAnalyticAllLevels,
    }
  );

  const { data: dataMapView } = useQuery(
    [`${QUERY_KEY.DATA_MAP_KEY}_SUMMARY`, clientId, cropType, levelId],
    () => sendGetAllLocation(clientId ?? null, cropType, levelId ?? 'null'),
    {
      enabled: !!levelId && !!clientId && !!cropType,
    }
  );

  const allLocationCropType = dataMapView?.data as any[];

  const checkIsLastRow = (index: number) => {
    return finalData?.length - 1 === index;
  };

  const findRecursiveLevelId = (levelInfo: IParent, pointId: string): boolean => {
    if (levelInfo._id === pointId) return true;
    if (levelInfo.parent) {
      return findRecursiveLevelId(levelInfo.parent, pointId);
    }
    return false;
  };

  const allLocationWithPhotoIntelligenceData = allLocationCropType?.map(({ _id, name }) => {
    const filteredListData = dataPhotoIntelligence
      ?.filter((ele) => findRecursiveLevelId(ele.level, _id))
      ?.map((item) => item.data)
      .flat()
      ?.filter((item) =>
        status.listData
          .filter((each) => each.value)
          ?.map((each) => each.key)
          ?.includes(item.status)
      )
      ?.filter((item) =>
        frequency.listData
          .filter((each) => each.value)
          ?.map((each) => each.key)
          ?.includes(item.frequency)
      )
      ?.map((item) => ({
        severityLevels: item.severityLevels.filter((ele) =>
          severityLevel.listData
            .filter((each) => each.value)
            ?.map((each) => each.key)
            ?.includes(ele)
        ),
        names: item.names,
      }));

    const reducedListData = filteredListData?.reduce(
      (acc, cur) => {
        acc.severityLevels = [...acc.severityLevels, ...cur.severityLevels];
        acc.names = [...acc.names, ...cur.names];
        return acc;
      },
      { severityLevels: [], names: [] }
    );

    const generateDataWithSeverityAndNames = (
      filteredData: { severityLevels: SeverityLevelEnum[]; names: string[] } | undefined
    ) => {
      if (!filteredData) return [];
      const { severityLevels, names } = filteredData || {};

      const map = new Map<SeverityLevelEnum, IMapValue>([
        [SeverityLevelEnum.HIGH, { value: 0, names: [] }],
        [SeverityLevelEnum.MEDIUM, { value: 0, names: [] }],
        [SeverityLevelEnum.LOW, { value: 0, names: [] }],
        [SeverityLevelEnum.GROUND_FEATURES, { value: 0, names: [] }],
      ]);
      severityLevels?.forEach((item, index) => {
        const mapItem = map.get(item);
        if (mapItem) {
          const set = new Set(mapItem.names);
          const value = mapItem.value + 1;
          set.add(names[index]);
          map.set(item, { value, names: [...set] });
        }
      });
      return map;
    };

    const generatedData = generateDataWithSeverityAndNames(reducedListData);

    const convertedData = Array.from(generatedData || [], ([key, info]) => ({ key, info }))?.map((item) => ({
      key: item.key,
      info: item.info,
      color: SeverityColor[item.key],
    }));

    return {
      _id,
      name,
      kpData: convertedData,
    };
  });

  const sortData = allLocationWithPhotoIntelligenceData?.sort((a: ITableData, b: ITableData) => {
    return isSortAsc ? a.name?.localeCompare(b.name) : b.name?.localeCompare(a.name);
  });

  const flatData = sortData?.map((item) => item.kpData)?.flat();
  const totalData = flatData?.reduce<IKpData[]>((acc, cur) => {
    const existed = acc.find((item) => item.key === cur.key);

    if (existed) {
      existed.info.value += cur.info.value;
      const set = new Set([...existed.info.names, ...cur.info.names]);
      existed.info.names = [...set];
    } else {
      acc.push({ ...cur, info: { ...cur.info } });
    }

    return acc;
  }, []);

  const finalData = [...(sortData || []), { _id: '1', name: t('trans.total'), kpData: totalData }];

  const renderTooltipCircleBox = (names: string[]) => {
    return names.map((name, index) => (
      <Typography key={index}>{t(convertTextToMultiLanguage(name, 'photo_intelligence'))}</Typography>
    ));
  };

  if (!dataPhotoIntelligence || dataPhotoIntelligence?.length === 0) return null;

  return (
    <TableContainer
      sx={{
        overflow: 'hidden',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
        mb: '12px',
      }}>
      <Table>
        <TableHead>
          <TableRow
            component="tr"
            sx={{
              backgroundColor: (theme) => theme.palette.background.paper,
            }}>
            {tableHeaders.map((item) => (
              <TableCellHeaderSummary
                key={item.key}
                component="th"
                sx={{
                  padding: '8px',
                }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                  {t(item.label)}
                  {item.key === TableKeyEnum.LEVEL && (
                    <SvgIcon
                      component={isSortAsc ? DescIcon : AscIcon}
                      inheritViewBox
                      sx={{ fontSize: '14px', cursor: 'pointer' }}
                      onClick={() => setIsSortAsc(!isSortAsc)}
                    />
                  )}
                </Box>
              </TableCellHeaderSummary>
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            pt: 0,
            flexDirection: 'column',
            minHeight: '510px',
            maxHeight: '510px',
            overflowY: 'scroll',
            '-ms-overflow-style': 'none' /* IE and Edge */,
            scrollbarWidth: 'none' /* Firefox */,
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}>
          {finalData?.map((item, index) => (
            <TableRow key={item._id}>
              <TableCellSummary component="td" sx={{ padding: '8px', fontWeight: checkIsLastRow(index) ? 700 : 400 }}>
                {item?.name}
              </TableCellSummary>
              {item.kpData?.map((ele) => (
                <TableCellSummary component="td" key={ele.key} sx={{ padding: '12px' }}>
                  <Tooltip title={renderTooltipCircleBox(ele.info.names)} color="red">
                    <CircleBox key={ele.key} color={ele.color} label={ele.info.value} ref={ref} isCollapseSpace />
                  </Tooltip>
                </TableCellSummary>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PhotoIntelligenceSummaryTable;
